.Toastify__toast-container--top-right {
  top: 60px;
}

.Toastify__toast {
  width: fit-content;
  max-width: 320px;

  @media only screen and (max-width: 480px) {
    border-radius: 6px;
    right: 16px;
    margin-bottom: 16px;
  }
}
